import service from '@/utils/request';

// @Tags RmParamGrouplevelData
// @Summary 创建RmParamGrouplevelData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamGrouplevelData true "创建RmParamGrouplevelData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParamGrouplevelData/createRmParamGrouplevelData [post]
export var createRmParamGrouplevelData = function createRmParamGrouplevelData(data) {
  return service({
    url: "/rmParamGrouplevelData/createRmParamGrouplevelData",
    method: 'post',
    data: data
  });
};

// @Tags RmParamGrouplevelData
// @Summary 删除RmParamGrouplevelData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamGrouplevelData true "删除RmParamGrouplevelData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParamGrouplevelData/deleteRmParamGrouplevelData [delete]
export var deleteRmParamGrouplevelData = function deleteRmParamGrouplevelData(data) {
  return service({
    url: "/rmParamGrouplevelData/deleteRmParamGrouplevelData",
    method: 'delete',
    data: data
  });
};

// @Tags RmParamGrouplevelData
// @Summary 删除RmParamGrouplevelData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmParamGrouplevelData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParamGrouplevelData/deleteRmParamGrouplevelData [delete]
export var deleteRmParamGrouplevelDataByIds = function deleteRmParamGrouplevelDataByIds(data) {
  return service({
    url: "/rmParamGrouplevelData/deleteRmParamGrouplevelDataByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmParamGrouplevelData
// @Summary 更新RmParamGrouplevelData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamGrouplevelData true "更新RmParamGrouplevelData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmParamGrouplevelData/updateRmParamGrouplevelData [put]
export var updateRmParamGrouplevelData = function updateRmParamGrouplevelData(data) {
  return service({
    url: "/rmParamGrouplevelData/updateRmParamGrouplevelData",
    method: 'put',
    data: data
  });
};

// @Tags RmParamGrouplevelData
// @Summary 用id查询RmParamGrouplevelData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamGrouplevelData true "用id查询RmParamGrouplevelData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmParamGrouplevelData/findRmParamGrouplevelData [get]
export var findRmParamGrouplevelData = function findRmParamGrouplevelData(params) {
  return service({
    url: "/rmParamGrouplevelData/findRmParamGrouplevelData",
    method: 'get',
    params: params
  });
};

// @Tags RmParamGrouplevelData
// @Summary 分页获取RmParamGrouplevelData列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmParamGrouplevelData列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParamGrouplevelData/getRmParamGrouplevelDataList [get]
export var getRmParamGrouplevelDataList = function getRmParamGrouplevelDataList(params) {
  return service({
    url: "/rmParamGrouplevelData/getRmParamGrouplevelDataList",
    method: 'get',
    params: params
  });
};